<template>
  <Dialog>
    <div class="px-md pt-sm">
      <Form
        id="create-favorite-list"
        :submit-label="t('myProducts.favoriteLists.form.save')"
        :is-loading="isLoading"
        @submit="onSubmit"
      >
        <template #errors>
          <div
            v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          >
            <UnknownError />
          </div>
        </template>
        <template #default>
          <TextInput
            name="listName"
            :model-value="list?.name"
            label="formFields.listName"
            placeholder="formFields.listName"
            required-message="favoritelists.listNameRequired"
            :autofocus="true"
          />
        </template>
      </Form>
      <div
        class="text-center cursor-pointer my-md font-deco-alt text-primary-base"
      >
        <p @click="dialogStore.closeDialog(DialogIdent.NEW_EDIT_FAVORITE_LIST)">
          {{ t('formFields.form.cancel') }}
        </p>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { TextInput } from '~/complib';
import Form from '~/components/formFields/form/form.vue';
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import { DialogIdent } from '~/stores/useDialogStore';
import { useNewEditFavoriteList } from '../useNewEditFavoriteList';
import { ErrorIdent } from '~/@types/errorIdents';

const { onSubmit, errors, isLoading, list, dialogStore } =
  useNewEditFavoriteList();
const { t } = useTrans();
</script>
